import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Header from '../components/property_list/Header';
import Tax from '../components/accounting-service/Tax';
import CtaBottom from '../components/property-management/CtaBottom';
import Footer from '../components/footer/Footer';
import React, { useEffect } from 'react';
import A from '../components/link/A';


const title = 'Accounting services';
const title_addition = 'Chartered accountant overseeing the rental operation';
const subtitle = "— To achieve successful property investment accounting is another important factor. Our chartered accountant partners are available for conducting tax declarations, tax optimizations both for your rental income or in the event of buying/selling a property.";

const header_cta =
    <A
        href="contact"
        className="bg-jazz-blue text-white hover:bg-indigo-600 mt-10 block w-100 sm:w-1/2 md:w-1/3 xl:w-1/3 py-3 px-6 border border-transparent rounded-md text-center font-medium"
    >
    Get in touch
    </A>;



function AccountingService() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Header title={title} title_addition={title_addition} subtitle={subtitle} cta={header_cta} />
      <Tax />
      <CtaBottom title="Still have questions?" subtitle="Can’t find the answer you’re looking for? Please chat to our friendly team." button_text="Tell us how we can help" href="contact" />
      <Footer />
    </>
  );
}

export default AccountingService;
