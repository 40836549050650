import { Fragment, useState} from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next';


const languages = [
  {
    id: 'en',
    name: 'EN',
    href: 'images/EN.svg'
  },
  {
    id: 'hu',
    name: 'HU',
    href: 'images/HU.svg'
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function getRestOfUrl(t, item) {
  let url = new URL(window.location.href).pathname.split('/');
  let url_arr = url.slice(2, url.length);

  for (var i = 0 ; i < url_arr.length; i++) {
    url_arr[i] = t(url_arr[i], {lng: item.id, ns: 'routes'});
  }

  return url_arr.join('/')
}

function itemSelected (item, setSelected, i18n, t) {
  setSelected(item);
  let restOfUrl = getRestOfUrl(t, item);
  window.location.href = "/" + item.id + "/" + restOfUrl;
}

export default function LanguageSelector(props) {
  const {t, i18n} = useTranslation();
  const [selected, setSelected] = useState(languages[0]);

  let lang = new URL(window.location.href).pathname.split("/")[1];
  let current_lang = languages.filter((item) => item.id === lang )[0];

  return (
    <Listbox value={current_lang} onChange={(e) => {itemSelected(e, setSelected, i18n, t);}}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="ml-4 w-24 flex items-center justify-center whitespace-nowrap rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium shadow-sm">
              <span className="flex items-center">
                <img
                  width="20"
                  src={ "/" + current_lang.href}
                  alt=""
                />
                <span className="ml-3 block">{current_lang.name}</span>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-28 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {languages.map((language) => (
                  <Listbox.Option
                    key={language.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={language}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            width="20"
                            src={ "/" + language.href}
                            alt=""
                          />
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                          >
                            {language.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
