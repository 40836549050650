import A from '../link/A';

export default function Cta() {
    return (
      <div className="bg-jazz-neon max-w-7xl my-20 rounded-3xl mx-6 xl:mx-auto">
        <div className="mx-auto max-w-3xl py-10 px-6 text-center sm:py-10 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            <span className="block">0 HUF management free for 3 months</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-white">
          Sign up for one of our property management packages and enjoy the benefits of the service free of charge for the first 3 months!
          </p>
          <div className="w-full flex gap-4 text-center justify-center">
            <A
                href="contact"
                className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium hover:bg-indigo-50 sm:w-auto"
            >
                Details
            </A>
            <A
                href="contact"
                className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-jazz-blue text-white px-5 py-3 text-base font-medium hover:bg-indigo-50 sm:w-auto"
            >
                Get an offer
            </A>
          </div>

        </div>
      </div>
    )
  }
