import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Header from '../components/property_list/Header';
import Image from '../photography-service/Image';
import LatestProperties from '../components/latest-properties/LatestProperties';
import Footer from '../components/footer/Footer';
import React, { useEffect } from 'react';


const title = 'Photography services';
const title_addition = 'Professional in-house photography';
const subtitle = <>— Should you wish to create professional high quality photos of your property we have the knowledge and the right tools to cover you with that. Sales campaigns and lease campaigns become faster and more effective with the right photos. <br /><br/> We also make 3D virtual tours that maximize the potential to find the right buyer or tenant and not to waste any time.</>;

const header_cta =
    <a
        href="contact"
        className="bg-jazz-blue text-white hover:bg-indigo-600 mt-10 block w-100 sm:w-1/2 md:w-1/3 xl:w-1/3 py-3 px-6 border border-transparent rounded-md text-center font-medium"
    >
    Ask for a quote
    </a>;


function PhotographyService() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Header title={title} title_addition={title_addition} subtitle={subtitle} cta={header_cta} />
      <Image />
      <LatestProperties title="Some of our works" amount="3" />
      <Footer />
    </>
  );
}

export default PhotographyService;
