import { BoltIcon, EnvelopeIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline'
import A from '../link/A';

export default function PropertySales() {
  return (
    <div className="overflow-hidden bg-jazz-grey">
      <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8 lg:py-40">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          <div className="lg:col-span-1">
            <h2 className="text-lg font-semibold text-indigo-600 mb-4">We help with</h2>
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Property sales</h2>
            <p className="mt-2 text-base text-gray-600">Do you have a property to sell? With Jazz Immo, you can be confident that your property for sale will be shown to all the right buyers. We manage the sale of your property with the care it deserves, ensuring each decision maximizes its value and ensures a smooth process, from start to finish. Talk to us to access a host of potential buyers interested in property for sale.</p>

            <h3 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">Wondering how much your property is worth?</h3>
            <p className="mt-2 text-base text-gray-600">Our professional team will help you remove any doubts. We have been valuing Budapest properties for over 15 years, so it’s safe to say we know the market.</p>

            <A
              href="request-valuation"
              className="bg-jazz-blue text-white hover:bg-indigo-600 mt-8 block w-100 sm:w-1/2 md:w-1/2 xl:w-1/2 py-3 px-6 border border-transparent rounded-md text-center font-medium"
            >
              Request valuation
            </A>
          </div>
          <dl className="mt-20 grid grid-cols-1 gap-16 sm:grid-cols-1 sm:gap-x-12 lg:col-span-1 lg:mt-0">
                <img
                    className="" aria-hidden="true"
                    src="/images/property_sales.jpg"
                    alt=""
                />
          </dl>
        </div>
      </div>
    </div>
  )
}
