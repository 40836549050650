import A from '../link/A';

export default function AboutYourProperty() {
  return (
    <div className="overflow-hidden">
      <div className="relative mx-auto max-w-7xl py-8 px-6 sm:py-8 lg:px-8 lg:py-18 mb-20">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          <div className="lg:col-span-1">
            <h2 className="text-lg font-semibold text-indigo-600 mb-4">We take time to learn</h2>
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">About your property</h2>
              <p className="mt-6 text-base text-gray-600">
              Our in-house team is available to create a 3D walking tour of your property that will result in a faster and more effective sales campaign.
              </p>
              <p className="mt-6 text-base text-gray-600">
              If you instruct us for a non-exclusive sales mandate we will perform a free of charge official valuation to give you an insight of the current state of the local market.
              </p>

            <A
              href="contact"
              className="bg-jazz-blue text-white hover:bg-indigo-600 mt-8 block w-100 sm:w-1/2 md:w-1/2 xl:w-1/3 py-3 px-6 border border-transparent rounded-md text-center font-medium"
            >
              Let's get started
            </A>
          </div>
          <dl className="mt-20 grid grid-cols-1 gap-16 sm:grid-cols-1 sm:gap-x-12 lg:col-span-1 lg:mt-0">
                <img
                    className="" aria-hidden="true"
                    src="/images/property_sales_page.png"
                    alt=""
                />
          </dl>
        </div>
      </div>
    </div>
  )
}
