import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Hero from '../components/hero/Hero';
import Footer from '../components/footer/Footer';
import Cta from '../components/cta/Cta';
import CtaWithVideo from '../components/cta/CtaWithVideo';
import Pricing from '../components/pricing/Pricing';
import Services from '../components/services/Services';
import TenantServices from '../components/tenant-services/TenantServices';
import LatestProperties from '../components/latest-properties/LatestProperties';
import PropertySales from '../components/property-sales/PropertySales';
import LearnMore from '../components/learn-more/LearnMore';
import React, { useEffect } from 'react';

function Home() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Hero />
      <Services />
      <CtaWithVideo />
      <Pricing />
      <Cta />
      <TenantServices />
      <LatestProperties title="Latest properties to rent" amount="3" />
      <PropertySales />
      <LatestProperties title="Latest properties to buy" amount="3" />
      <LearnMore />
      <Footer />
    </>
  );
}

export default Home;
