import { CheckCircleIcon } from '@heroicons/react/24/outline'

const marketing_channel_items = [
    "We are linked with the largest property portals in Hungary",
    "We have access to social media groups where expats and students search for properties",
    "We cooperate with a network of external agents",
    "We take high quality professional photos of your property",
    "We put up boards and signages"
];

const viewings_items = [
    "Our multi-lingual leasing agents will negotiate the best deal possible",
    "Notes from the viewings will be available for you in your Landlord App"
];

const contracting_items = [
    "Bilingual contracts, generally for 12 months",
    "Security deposits of 2 months required",
    "First month’s rent and service charges are paid upon moving in",
    "Photo documented handover protocol that Landlords have access to through Jazz Immo Landlord App",
    "Tenant screening - we require identity documents, proof of employment or school enrollment, bank account statements",
    "Notice of intent to vacate signed before a notary public - optional"
];

export default function WhatWeDo() {
  return (
    <div className="overflow-hidden">
      <div className="relative mx-auto max-w-7xl py-10 px-6 sm:py-10 lg:px-8 lg:py-10">
      <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-20">What we do for a successful leasing campaign</h2>

        <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          <div className="lg:col-span-1">
            <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-xl mb-6">Marketing Channels</h2>

            <ul role="list">

                {marketing_channel_items.map((item, index) => (
                    <li className="mb-2" key={index}>
                    <CheckCircleIcon className="h-6 w-6 mr-2 mb-1 inline-block text-jazz-blue" aria-hidden="true" />
                        {item}
                    </li>
                ))}
            </ul>

            <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-xl mb-6 mt-6">Viewings</h2>

            <ul role="list">

                {viewings_items.map((item, index) => (
                    <li className="mb-2" key={index}>
                    <CheckCircleIcon className="h-6 w-6 mr-2 mb-1 inline-block text-jazz-blue" aria-hidden="true" />
                        {item}
                    </li>
                ))}
            </ul>

          </div>
          <div className="lg:col-span-1">
            <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-xl mb-6 mt-6 lg:mt-0">Contracting</h2>

            <ul role="list">

                {contracting_items.map((item, index) => (
                    <li className="mb-2" key={index}>
                    <CheckCircleIcon className="h-6 w-6 mr-2 mb-1 inline-block text-jazz-blue" aria-hidden="true" />
                        {item}
                    </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
