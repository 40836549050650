import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Header from '../components/property_list/Header';
import Quote from '../components/property-management/Quote';
import Image from '../components/property-management/Image';
import WealthProtection from '../components/property-management/WealthProtection';
import Yields from '../components/property-management/Yields';
import Cta from '../components/property-management/Cta';
import Comparison from '../components/property-management/Comparison';
import HowToSwitch from '../components/property-management/HowToSwitch';
import CtaBottom from '../components/property-management/CtaBottom';
import Contact from '../components/property-management/Contact';
import Footer from '../components/footer/Footer';
import React, { useEffect } from 'react';
import A from '../components/link/A';


const title = <>Property management</>;
const subtitle = "— Whether you are renting a single property, or own a large investment portfolio, our management team can provide professional peace of mind.";
const title_addition = 'Smart property management services for your Budapest Property';

const quote_title = '“Because passive income requires active management”';
const quote_subtitle = 'We have built a team and a proprietary technology to take care of all your rental property needs.';

const app_title = "Jazz Immo Landlord App";
const app_subtitle = "The key to a successful property investment is to be on top of what is going on with your property. We have built a technology that is allowing you to track all balances of rent, utilities and service charge payments, follow and approve maintenance issues and store all documents related to your investment.";

const app_cta =
    <A
        href="contact"
        className="bg-jazz-blue text-white hover:bg-indigo-600 mt-8 block w-100 sm:w-1/2 md:w-1/4 xl:w-1/4 py-3 px-6 border border-transparent rounded-md text-center font-medium m-auto"
    >
    Try a demo
    </A>;

function PropertyManagement() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Header title={title} title_addition={title_addition} subtitle={subtitle} />
      <Quote title={quote_title} subtitle={quote_subtitle} />
      <Image />
      <Quote title={app_title} subtitle={app_subtitle} cta={app_cta} />
      <WealthProtection />
      <Yields />
      <Cta />
      <Comparison />
      <HowToSwitch />
      <CtaBottom title="Still have questions?" subtitle="Can’t find the answer you’re looking for? Please chat to our friendly team." button_text="Get started with a consultation" href="contact" />
      <Contact />
      <Footer />
    </>
  );
}

export default PropertyManagement;
