import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Header from '../components/property_list/Header';
import Prices from '../components/valutation-services/Prices';
import CtaBottom from '../components/property-management/CtaBottom';
import Footer from '../components/footer/Footer';
import React, { useEffect } from 'react';
import A from '../components/link/A';


const title = 'Valuation services';
const title_addition = 'Providing valuation services in Budapest for 15 years';
const subtitle = "— We perform valuations for a big variety of customers. We are happy to assist you if you are looking to sell your property, in case you are securing financing and wish to carry out a valuation for your bank. We work for private individuals, financial and governmental institutions as well.";

const header_cta =
    <A
        href="request-valuation"
        className="bg-jazz-blue text-white hover:bg-indigo-600 mt-10 block w-100 sm:w-1/2 md:w-1/3 xl:w-1/3 py-3 px-6 border border-transparent rounded-md text-center font-medium"
    >
    Request valutation
    </A>;

function ValutationService() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Header title={title} title_addition={title_addition} subtitle={subtitle} cta={header_cta} />
      <Prices />
      <CtaBottom title="Still have questions?" subtitle="To get a quote please do not hesitate to contact us." button_text="Request valutation" href="request-valutation" />
      <Footer />
    </>
  );
}

export default ValutationService;
