  import A from '../link/A';

  export default function Footer() {
    return (
      <footer className="bg-gray-800" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid xl:grid-cols-6 lg:grid-cols-3 sm:grid-cols-1 xl:gap-2">

            <div className="col-span-1 text-center">
              <img
                className="h-10 m-auto"
                src="/jazz_immo_logo_light.svg"
                alt="Jazz Immo"
              />
            </div>

            <div className="xl:col-span-4 md:grid-cols-1 text-center xl:px-20 mt-8 lg:mt-0">
              <div className="md:grid md:grid-cols-4 md:gap-8">
                <div>
                  <ul role="list" className="mt-3 space-y-4">
                    <li>
                        <A href="" className="text-base text-gray-500 hover:text-white">Landlord services</A>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul role="list" className="mt-3 space-y-4">
                    <li>
                        <A href="rent" className="text-base text-gray-500 hover:text-white">Rent</A>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul role="list" className="mt-3 space-y-4">
                    <li>
                        <A href="buy" className="text-base text-gray-500 hover:text-white">Buy</A>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul role="list" className="mt-3 space-y-4">
                    <li>
                        <A href="about-us" className="text-base text-gray-500 hover:text-white">About us</A>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="col-span-1 mt-8 lg:mt-0">
              <p className="text-base text-center lg:text-right text-gray-500">
                © 2022 Jazz Immo <br />Property Management
              </p>
            </div>

          </div>
        </div>
      </footer>
    )
  }
