import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Header from '../components/property_list/Header';
import Filter from '../components/property_list/Filter';
import LatestProperties from '../components/latest-properties/LatestProperties';
import Footer from '../components/footer/Footer';
import React, { useEffect } from 'react';


const title = <>Rent property in <br /><span className='inline-block font-medium mt-2'>Budapest</span></>;
const subtitle = "— Some nice message could go over here why to choose JazzImmo for renting in Budapest";

function RentPropertyList() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Header title={title} subtitle={subtitle} />
      <Filter />
      <LatestProperties title="" amount="15" />
      <Footer />
    </>
  );
}

export default RentPropertyList;
