import {
    PhoneIcon,
    ClockIcon,
    BuildingOffice2Icon
  } from '@heroicons/react/24/outline'

  const features_first_row = [
    {
      name: 'Our office',
      description: <>13, Király Street <br /> 1073 Budapest</>,
      icon: BuildingOffice2Icon,
    },
    {
      name: 'Opening hours',
      description: 'Mon-Fri from 8am to 5pm.',
      icon: ClockIcon,
    },
    {
      name: 'Phone',
      description: '+36 70',
      icon: PhoneIcon,
    }
  ]

  export default function Contact() {
    return (
      <div className="relative bg-white py-10 sm:py-32 lg:py-0 mb-20">
        <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
          <h2 className="text-lg font-semibold text-indigo-600">Contact us</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            We’d love to hear from you
          </p>
          <div className="mt-20">
            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
              {features_first_row.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                          <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base leading-7 text-gray-600">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
