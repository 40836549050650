import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Header from '../components/property_list/Header';
import FindATenant from '../components/leasing-service/FindATenant';
import Cta from '../components/leasing-service/Cta';
import WhatWeDo from '../components/leasing-service/WhatWeDo';
import Footer from '../components/footer/Footer';
import React, { useEffect } from 'react';


const title = 'Leasing services';
const title_addition = 'Experts in short and long lets across Budapest';
const subtitle = "— Thinking of finding a new quality tenant? Do you want to handle the property management yourself but some professional tools would help you do it right?";


function LeasingService() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Header title={title} title_addition={title_addition} subtitle={subtitle} />
      <FindATenant />
      <WhatWeDo />
      <Cta />
      <Footer />
    </>
  );
}

export default LeasingService;
