export default function Title(props) {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-16 px-6 sm:pb-10 pt-4 lg:px-8">
                <div className="group relative">
                    <div className="mt-4 flex items-center justify-between space-x-8 text-gray-900">
                        <h2 className="text-4xl font-normal tracking-tight text-gray-900 sm:text-5xl lg:text-5xl mb-2">
                            Tigris street 28
                        </h2>
                        <p className="text-2xl font-normal tracking-tight text-gray-900 sm:text-3xl" >District VII.</p>
                    </div>
                    <div className="flex items-center justify-between space-x-8 text-base">
                        <p className="mt-1 text-2xl font-normal tracking-tight text-gray-900 sm:text-3xl">240,000 HUF</p>
                        <p className="mt-1 text-2xl sm:text-2xl text-gray-500">/month</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
