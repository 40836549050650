import { CheckCircleIcon } from '@heroicons/react/24/solid';
import A from '../link/A';

const pricing = {
  tiers: [
    {
      title: 'Standard service',
      description: 'For landlords seeking to manage their tenant relationship directly',
      features: [
        'Owner representation',
        'Regular inspections',
        'Maintenance',
        'Jazz Immo Property Management App'
      ],
      cta: 'Sing me up'
    },
    {
      title: 'Managed service',
      description: 'For landlords looking for a full property management service',
      features: [
        'Home management+',
        'Leasing services',
        'Tenant management',
        'Utilities management',
        'Property handover',
        'Rent collection',
        'Landlord App'
      ],
      cta: 'Tell me more',
    },
    {
      title: 'Investment Advisory',
      description: 'For landlords desiring a more tailored service',
      features: [
        'Lease Management+',
        'Business structure setup',
        'Tax optimization',
        'Tax filing',
        'Accounting services',
        'Legal advisory',
        'Company address service'
      ],
      cta: 'Make an appointment',
    },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
  return (
    <div className="mx-auto max-w-7xl bg-white py-24 px-4 sm:px-6 lg:px-8">
      <h2 className="text-lg font-semibold text-indigo-600 mb-4">Our services packages</h2>
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
        What suits you best?
      </h2>

      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
          >
            <div className="flex-1">
                <div className="text-center">
                    <h3 className="text-2xl font-semibold text-gray-900">{tier.title}</h3>
                    <p className="mt-2 text-gray-500">{tier.description}</p>
                </div>


              {/* Feature list */}
              <ul role="list" className="mt-8 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <A
              href="contact"
              className={classNames(
                tier.mostPopular
                  ? 'bg-indigo-500 text-white hover:bg-indigo-600'
                  : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100',
                'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
              )}
            >
              {tier.cta}
            </A>
          </div>
        ))}
      </div>
    </div>
  )
}
