import './App.css';
import Home from './pages/Home';
import RentPropertyList from './pages/RentPropertyList';
import BuyPropertyList from './pages/BuyPropertyList';
import Contact from './pages/Contact';
import RequestValutation from './pages/RequestValutation';
import PropertyManagement from './pages/PropertyManagement';
import LeasingService from './pages/LeasingService';
import RenovationService from './pages/RenovationService';
import PropertySales from './pages/PropertySales';
import ValutationService from './pages/ValutationService';
import AccountingService from './pages/AccountingService';
import PhotographyService from './pages/PhotographyService';
import Property from './pages/Property';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";


export default function App() {
  const allowed_languages = ['en', 'hu'];

  let browserLang = navigator.language || navigator.userLanguage;
  let fallbackLang = browserLang.slice(0,2);

  if (!allowed_languages.includes(fallbackLang)) {
    fallbackLang = 'hu'
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/en">
          <Route index element={<Home />} />
          <Route path="rent" element={<RentPropertyList />} />
          <Route path="buy" element={<BuyPropertyList />} />
          <Route path="request-valuation" element={<RequestValutation />} />
          <Route path="contact" element={<Contact />} />
          <Route path="property-management" element={<PropertyManagement />} />
          <Route path="leasing-services" element={<LeasingService />} />
          <Route path="renovation-services" element={<RenovationService />} />
          <Route path="property-sales" element={<PropertySales />} />
          <Route path="valuation-services" element={<ValutationService />} />
          <Route path="accounting-services" element={<AccountingService />} />
          <Route path="photography-services" element={<PhotographyService />} />
          <Route path="property" element={<Property />} />
          <Route path="*" element={<Navigate to={"/" + fallbackLang} replace={true} />} />
        </Route>
        <Route path="/hu">
          <Route index element={<Home />} />
          <Route path="kiado-ingatlanok" element={<RentPropertyList />} />
          <Route path="elado-ingatlanok" element={<BuyPropertyList />} />
          <Route path="ertekbecsles-igenyles" element={<RequestValutation />} />
          <Route path="kapcsolat" element={<Contact />} />
          <Route path="lakaskezeles" element={<PropertyManagement />} />
          <Route path="berbeadas" element={<LeasingService />} />
          <Route path="felujitasok" element={<RenovationService />} />
          <Route path="ertekesites" element={<PropertySales />} />
          <Route path="ertekbecsles" element={<ValutationService />} />
          <Route path="konyveles" element={<AccountingService />} />
          <Route path="ingatlan-fotozas" element={<PhotographyService />} />
          <Route path="ingatlan" element={<Property />} />
          <Route path="*" element={<Navigate to={"/" + fallbackLang} replace={true} />} />
        </Route>
        <Route path="*">
          <Route path="*" element={<Navigate to={"/" + fallbackLang} replace={true} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
