import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    CogIcon,
    LockClosedIcon,
    ServerIcon,
    ShieldCheckIcon,
  } from '@heroicons/react/24/outline'

  const features_first_row = [
    {
      name: 'Inspections',
      description: 'Regular inspection are carried out to ensure the property is in good state and to prevent unnoticed damages. Inspections are photo documented in you JAZZ IMMO LANDLORD APP for you to check them. Tenants are also required to upload photos of the apartment on a regular basis, so even when the apartment is occupied you will know what is going on. Also any damages and repairs that may occur are documented and are visible in your LANDLORD APP.',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Tenant checking',
      description: 'We meet prospective tenants personally and collect all information for cross-check. Bank account details, legal documents, social profiles are collected and we verify tenant identities and organize tenant referencing checks.',
      icon: LockClosedIcon,
    },
    {
      name: 'Non-paying Tenant',
      description: 'In the event a breach of Lease Agreement occurs we are ready to take all actions to protect Landords’ interests. We go through the legal process of delivering notices and use legal assistance to reach eviction. For further protection we are able to carry out a notary approved eviction agreement that will ensure you will gain access to your property no matter what.',
      icon: ArrowPathIcon,
    }
  ]

  const features_second_row = [
    {
        name: 'Accounting Services',
        description: 'We are working closely with chartered accountants to manage tax filing and advise on tax optimization, so you can make sure that you are not forgetting anything and do not risk any fines from authorities.',
        icon: ShieldCheckIcon,
      },
      {
        name: 'Valutation Services',
        description: 'Should you wish to sell your property or use it against further financing we are here to help you with our valuation services through our in-house licenced chartered surveyors.',
        icon: CogIcon,
      },
  ]

  export default function WealthProtection() {
    return (
      <div className="relative bg-white py-10 sm:py-32 lg:py-20">
        <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
          <h2 className="text-lg font-semibold text-indigo-600">What you can expect from us is</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Wealth Protection
          </p>
          <div className="mt-20">
            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
              {features_first_row.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                          <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base leading-7 text-gray-600">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-2">
              {features_second_row.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                          <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="m-auto mt-5 text-base leading-7 text-gray-600 block w-80">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
