import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend  from 'i18next-http-backend'

const DETECTION_OPTIONS = {
    order: ['path'],
    caches: ['localStorage']
};

const allowed_languages = ['en', 'hu'];

let lang = new URL(window.location.href).pathname.split("/")[1];

if (!allowed_languages.includes(lang)) {
    lang = 'hu'
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: DETECTION_OPTIONS,
    lng: lang,
    interpolation: {
      escapeValue: false
    },
    preload: allowed_languages,
    /* react: {
        wait: true
    }, */
    //debug: true,
  });

  export default i18n;