import { BoltIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline'
import A from '../link/A';

export default function Tax() {
  return (
    <div className="bg-white py-20 sm:py-24 lg:py-10">
      <div className="mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">A better way to send money.</h2>
        <dl className="grid grid-cols-1 gap-16 lg:grid lg:grid-cols-2">

            <div className="p-10">
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white">
                  <ScaleIcon className="h-8 w-8" aria-hidden="true" />
                </div>
                <h2 className="mt-6 text-3xl font-semibold leading-8 tracking-tight text-gray-900">Jazz Immo App supporting your tax filing</h2>
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                <p className="mt-8 text-lg text-gray-500">This is a one stop shop, you will have all your necessary documents stored in your Landlord App ready to download or review any time you might need it. Also you will not miss any deadlines either as notifications reach you everytime.</p>


                <A
                    href="contact"
                    className="bg-jazz-blue text-white hover:bg-indigo-600 mt-10 block w-100 sm:w-1/2 md:w-1/3 xl:w-1/3 py-3 px-6 border border-transparent rounded-md text-center font-medium"
                >
                Try a demo
                </A>
              </dd>
            </div>

            <div className="bg-[#F9FAFB] p-10">

            </div>

        </dl>

      </div>
    </div>
  )
}
