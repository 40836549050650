import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Form from '../components/request-valutation/Form';
import Footer from '../components/footer/Footer';
import React, { useEffect } from 'react';


function RequestValutation() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Form />
      <Footer />
    </>
  );
}

export default RequestValutation;
