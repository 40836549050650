import A from '../link/A';

const products = [
    {
      id: 1,
      name: 'Tigris street, 28.',
      category: '1st District, 1016',
      href: '#',
      price: 'HUF 240.000 ',
      imageSrc: '/images/template_property_small.jpg',
      imageAlt:
        '',
    }
];

export default function LatestProperties(props) {
    let {title, amount, view_all} = props;

    amount = parseInt(amount) ? parseInt(amount) : 3;

    view_all = view_all === "1" ? true : false;

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl py-16 px-4 sm:pb-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex items-center justify-between space-x-4">
                <h2 className="text-3xl font-bold text-gray-900">{ title }</h2>
                <A href="property" className={ view_all ? "whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-500" : "hidden" }>
                    View all
                    <span aria-hidden="true"> &rarr;</span>
                </A>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3">
                {[...Array(amount)].map((el, index) => (
                    <div key={index} className="group relative">
                        <div className="aspect-w-4 aspect-h-3 overflow-hidden">
                            <img src={products[0].imageSrc} alt={products[0].imageAlt} className="object-cover object-center m-auto" />
                        </div>
                        <div className="mt-4 flex items-center justify-between space-x-8 text-base font-medium text-gray-900 pl-10 pr-10 sm:pl-0 sm:pr-0">
                            <h3>
                            <A href="property">
                                <span aria-hidden="true" className="absolute inset-0" />
                                {products[0].name}
                            </A>
                            </h3>
                            <p>{products[0].price}</p>
                        </div>
                        <div className="flex items-center justify-between space-x-8 text-base pl-10 pr-10 sm:pl-0 sm:pr-0">
                            <p className="mt-1 text-sm">{products[0].category}</p>
                            <p className="mt-1 text-sm text-gray-500">/month</p>
                        </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
    )
}
