import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import A from '../link/A';

export default function CtaWithVideo() {
  return (
    <div className="bg-jazz-grey">
        <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
            <div className="relative rounded-3xl bg-gray-800 overflow-hidden">
                <div className="h-56 bg-gray-800 sm:h-72 hidden lg:block md:absolute md:left-0 md:h-full w-1/2 xl:w-1/3">
                    <img
                    className="w-full"
                    src="/images/cta1_clean.png"
                    alt=""
                    />
                </div>
                <div className="relative mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                    <div className="md:ml-auto w-full lg:w-1/2 xl:w-2/3 md:pl-10">
                        <p className="mt-2 text-2xl font-bold tracking-tight text-white sm:text-3xl">
                        Following the successful leasing campaign you will be able to sign up for our Property Management Service and benefit from our low management fees and special offers.
                        </p>
                        <div className="mt-8">
                            <div className="inline-flex rounded-md shadow">
                            <A
                                href="contact"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                            >
                                Try our Landlord App
                            </A>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
