import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'

const features = [
  {
    title: 'A responsive team',
    description:
      "A responsive team of actual humans are here to help when you need us.",
      image: "/images/tenant_services.png",
  },
  {
    title: 'Multi lingual team',
    description:
      "We have a multi lingual team of property managers who will deliver solutions.",
      image: "/images/tenant_services.png",
  },
  {
    title: 'Tenant app',
    description:
      "You will get access to our Tenant App to make rental payments, document management and problem solving a smooth experience",
    image: "/images/tenant_services.png",
  },
]

export default function TenantServices() {
  return (
    <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">

        <div className="w-2/3">
            <h2 className="text-lg font-semibold text-indigo-600 mb-4">We are experienced with</h2>
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Tenant services</h2>
            <p className="mt-2 text-base text-gray-600">Renting through Jazz Immo you will meet an honest and professional company that provide you with an online platform to make your life easier. Follow rental payments, keep your documents, manage your lease, and get support in the app.</p>
        </div>

        <Tab.Group
            as="div"
            className="mt-6 grid grid-cols-1 items-center gap-y-1 gap-x-4 pt-10 sm:gap-y-6 md:mt-10 lg:mt-16 lg:grid-cols-12 lg:pt-0"
        >
        {({ selectedIndex }) => (
            <>
            <div className="mx-0 overflow-visible pb-0 col-span-6">
                <Tab.List className="mx-auto px-0 lg:mx-0 block gap-x-0 gap-y-2 whitespace-normal">
                {features.map((feature, featureIndex) => (
                    <div
                    key={feature.title}
                    className={clsx(
                        'group relative py-1 px-4 lg:p-6 border-l-4',
                        selectedIndex === featureIndex
                          ? 'border-l-jazz-neon'
                          : 'border-l-jazz-grey'
                      )}
                    >
                    <h3>
                        <Tab className="font-display text-lg font-bold [&:not(:focus-visible)]:focus:outline-none text-black">
                            <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                            {feature.title}
                        </Tab>
                    </h3>
                    <p className="mt-2 text-base lg:block text-gray-600">
                        {feature.description}
                    </p>
                    </div>
                ))}
                </Tab.List>
            </div>
            <Tab.Panels className="lg:col-span-6">
                {features.map((feature) => (
                <Tab.Panel key={feature.title} unmount={false}>
                    <div className="">
                        <img
                            className="" aria-hidden="true"
                            src={feature.image}
                            alt=""
                        />
                    </div>
                </Tab.Panel>
                ))}
            </Tab.Panels>
            </>
        )}
        </Tab.Group>
    </div>
  )
}
