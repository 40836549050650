import { Trans, useTranslation } from 'react-i18next';

export default function Hero() {
  const { t } = useTranslation(['main']);

  return (
    <div className="relative overflow-hidden bg-white">

        <div className="mx-auto max-w-[1800px] hidden lg:absolute lg:inset-0 lg:block" aria-hidden="true">

            <img
                className="absolute bottom-0 right-0 xl:w-max lg:w-1/2"
                src="/hero_budapest.png"
                alt=""
            />
        </div>


      <div className="relative pb-16 sm:pb-24 lg:pb-22">

        <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-12">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">

            <div className="md:text-center lg:col-span-12 lg:text-left">
              <h1>
                <span className="mt-1 w-full md:w-2/3 md:m-auto lg:m-0 xl:w-full block text-4xl font-bold tracking-tight sm:text-5xl xl:text-6xl">
                  <Trans ns="main">
                    <span className="block text-gray-900">Matching <span className="underline-06">people and property</span> in <br /> Budapest </span>
                  </Trans>
                </span>
              </h1>
            </div>

            <div className="sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:text-left">
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                { t('hero_subtitle')}
              </p>
              <div className="mt-8 sm:mx-auto sm:max-w-lg sm:text-center lg:mx-0 lg:text-left">
                <p className="text-base font-medium text-gray-900">{t('interested_in_learning_more')}</p>
                <form action="#" method="POST" className="mt-3 sm:flex">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-md border border-gray-300 pl-5 py-3 text-base placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:flex-1"
                    placeholder={t('enter_your_email')}
                  />
                  <button
                    type="submit"
                    className="mt-3 w-full rounded-md border border-transparent bg-jazz-light-blue px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-white hover:text-gray-500 hover:border hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:inline-flex sm:w-auto sm:flex-shrink-0 sm:items-center"
                  >
                    {t('get_started')}
                  </button>
                </form>
                <p className="mt-5 text-sm text-gray-400">
                  <Trans ns="main">
                    We care about the protection of your data. Read our
                    <a href="#" className="font-medium text-gray-500 underline ml-1">
                      Privacy Policy
                    </a>.
                  </Trans>
                </p>
              </div>
            </div>

          </div>
        </main>
      </div>
    </div>
  )
}
