import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Header from '../components/property_list/Header';
import Image from '../components/renovation-services/Image';
import LatestProperties from '../components/latest-properties/LatestProperties';
import Footer from '../components/footer/Footer';
import React, { useEffect } from 'react';
import A from '../components/link/A';


const title = 'Renovation services';
const title_addition = 'Wide network of contractors and in-house project management';
const subtitle = "— We have carried out multiple renovation and fit-out projects for residential and commercial properties. Our services range from interior design, graphics design, renovation works, fit-out project management and furnishing.";

const header_cta =
    <A
        href="contact"
        className="bg-jazz-blue text-white hover:bg-indigo-600 mt-10 block w-100 sm:w-1/2 md:w-1/4 xl:w-1/4 py-3 px-6 border border-transparent rounded-md text-center font-medium"
    >
    Ask for a quote
    </A>;


function RenovationService() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Header title={title} title_addition={title_addition} subtitle={subtitle} cta={header_cta} />
      <Image />
      <LatestProperties title="See our latest projects" amount="3" />
      <Footer />
    </>
  );
}

export default RenovationService;
