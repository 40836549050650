import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function generateHref(href) {
    let lang = new URL(window.location.href).pathname.split("/")[1];
    return "/" + lang + '/' + href;
}

export default function A(props) {
  const { t } = useTranslation(['routes']);

  let {
    className,
    href,
  } = props;

  let to = generateHref(t(href));

  return (
    <Link to={to} className={className} relative={false}>{props.children}</Link>
  );
}