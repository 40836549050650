export default function Header(props) {
  let {title, title_addition, subtitle, cta} = props;

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:pt-24 sm:pb-16  lg:flex lg:justify-between lg:px-8">
        <div className="max-w-2xl">
          <h2 className="text-4xl font-normal tracking-tight text-gray-900 sm:text-5xl lg:text-5xl mb-4">{title}</h2>

          <h3 className={ title_addition ? "text-xl font-normal tracking-tight text-gray-900 sm:text-xl lg:text-xl mb-4" : "hidden" }>{ title_addition }</h3>

          <p className="mt-8 text-lg text-gray-500">
            {subtitle}
          </p>

          {cta}
        </div>
      </div>
    </div>
  )
}
