import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import Image from '../components/property/Image';
import Title from '../components/property/Title';
import Footer from '../components/footer/Footer';
import React, { useEffect } from 'react';



function Property() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Breadcrumb />
      <Image />
      <Title />
      <Footer />
    </>
  );
}

export default Property;
