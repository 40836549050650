import { BoltIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline'


export default function Prices() {
  return (
    <div className="bg-white py-20 sm:py-24 lg:py-10">
      <div className="mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">A better way to send money.</h2>
        <dl className="grid grid-cols-1 gap-16 lg:grid lg:grid-cols-2">

            <div className="bg-[#F9FAFB] p-10">
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white">
                  <ScaleIcon className="h-8 w-8" aria-hidden="true" />
                </div>
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Residential Properties</p>
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">

                <div className="grid grid-cols-1 gap-2 lg:gap-16 lg:grid lg:grid-cols-2 mb-4 lg:mb-2">
                    <div>Residential plot</div>
                    <div>15,000 HUF</div>
                </div>

                <div className="grid grid-cols-1 gap-2 lg:gap-16 lg:grid lg:grid-cols-2 mb-4 lg:mb-2">
                    <div>Residential aparment</div>
                    <div>18,000 HUF</div>
                </div>

                <div className="grid grid-cols-1 gap-2 lg:gap-16 lg:grid lg:grid-cols-2 mb-4 lg:mb-2">
                    <div>Residential house</div>
                    <div>23,000 HUF</div>
                </div>

              </dd>
            </div>

            <div className="bg-[#F9FAFB] p-10">
              <dt>
                <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white">
                  <ScaleIcon className="h-8 w-8" aria-hidden="true" />
                </div>
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Commercial Properties</p>
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">

                <div className="grid grid-cols-1 gap-2 lg:gap-16 lg:grid lg:grid-cols-2 mb-4 lg:mb-2">
                    <div>Plot</div>
                    <div>25,000 HUF</div>
                </div>

                <div className="grid grid-cols-1 gap-2 lg:gap-16 lg:grid lg:grid-cols-2 mb-4 lg:mb-2">
                    <div>Commercial unit</div>
                    <div>25,000 HUF</div>
                </div>

                <div className="grid grid-cols-1 gap-2 lg:gap-16 lg:grid lg:grid-cols-2 mb-4 lg:mb-2">
                    <div>Workshop</div>
                    <div>25,000 HUF</div>
                </div>

                <div className="grid grid-cols-1 gap-2 lg:gap-16 lg:grid lg:grid-cols-2 mb-4 lg:mb-2">
                    <div>Offices</div>
                    <div>30,000 HUF</div>
                </div>

              </dd>
            </div>

        </dl>
        <p className="mt-8 text-lg text-gray-500">Valuation prices do not depend on the value of the property.</p>
      </div>
    </div>
  )
}
