import A from '../link/A';

export default function Cta() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
          <div className="overflow-hidden rounded-3xl bg-jazz-blue shadow-xl lg:grid lg:grid-cols-2 lg:gap-4 lg:bg-cta-pattern bg-left-bottom bg-no-repeat">
            <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-jazz-neon text-5xl font-bold">0 HUF</h2>

                <div className="block w-1/4 h-1 bg-white mt-16 mb-3"></div>

                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                  <span className="block">Free management for 3 months</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-indigo-200">
                    Sign up for one of our property management packages and enjoy the benefits of the service free of charge for the first 3 months!
                </p>
                <A
                  href="contact"
                  className="mt-8 inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                >
                  Get an offer
                </A>
              </div>
            </div>
            <div className="hidden lg:block">
              <img
                className="sm:translate-x-14 lg:translate-y-0"
                src="/images/cta2.png"
                alt="App screenshot"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
