import '../App.css';
import Navigation from '../components/navigation/Navigation';
import Header from '../components/property_list/Header';
import Footer from '../components/footer/Footer';
import AboutYourProperty from '../components/property-sales-page/AboutYourProperty';
import React, { useEffect } from 'react';


const title = 'Property sales';
const title_addition = 'You can sell the property if you know the property. We take the time to get to know your property.';
const subtitle = "— We have 15 years experience in buying and selling properties in Budapest, so it is fair to say we know the market. We are happy to assist you in your transaction, making sure the representation of your property is up to modern marketing standards in order to meet prime market sale prices.";


function LeasingService() {
  useEffect(() => {
    document.title = 'Jazz Immo';
  }, []);

  return (
    <>
      <Navigation />
      <Header title={title} title_addition={title_addition} subtitle={subtitle} />
      <AboutYourProperty />
      <Footer />
    </>
  );
}

export default LeasingService;
