import { Fragment } from 'react'
import { CheckCircleIcon, MinusIcon,  } from '@heroicons/react/20/solid'

const tiers = [
  {
    name: 'Standard service',
    href: '#',
    priceMonthly: 9,
    description: 'Long let only (6 months+)'
  },
  {
    name: 'Managed service',
    href: '#',
    priceMonthly: 29,
    description: 'Short and long let available',
  },
  {
    name: 'Investment advisory',
    href: '#',
    priceMonthly: 59,
    description: 'Short and long let available',
  },
]
const sections = [
  {
    name: 'Features',
    features: [
      { name: 'Molestie lobortis massa.', tiers: { 'Standard service': true, 'Managed service': true, 'Investment advisory': true } },
      { name: 'Urna purus felis.', tiers: { 'Standard service': true, 'Managed service': true, 'Investment advisory': true } },
      { name: 'Tellus pulvinar sit dictum.', tiers: { 'Managed service': true, 'Investment advisory': true } },
      { name: 'Convallis.', tiers: { 'Managed service': 'Up to 20 users', 'Investment advisory': 'Up to 50 users' } },
    ],
  },
  {
    name: 'Reporting',
    features: [
      { name: 'Adipiscing.', tiers: { 'Standard service': true, 'Managed service': true, 'Investment advisory': true } },
      { name: 'Eget risus integer.', tiers: { 'Managed service': true, 'Investment advisory': true } },
      { name: 'Gravida leo urna velit.', tiers: { 'Investment advisory': true } },
      { name: 'Elementum ut dapibus mi feugiat cras nisl.', tiers: { 'Investment advisory': true } },
    ],
  },
  {
    name: 'Support',
    features: [
      { name: 'Sit dignissim.', tiers: { 'Standard service': true, 'Managed service': true, 'Investment advisory': true } },
      { name: 'Congue at nibh et.', tiers: { 'Managed service': true, 'Investment advisory': true } },
      { name: 'Volutpat feugiat mattis.', tiers: { 'Managed service': true, 'Investment advisory': true } },
      { name: 'Tristique pellentesque ornare diam sapien.', tiers: { 'Investment advisory': true } },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Comparison() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl bg-white py-16 sm:py-24 sm:px-6 lg:px-8">
        {/* xs to lg */}
        <div className="mx-auto max-w-2xl space-y-16 lg:hidden">
          {tiers.map((tier, tierIdx) => (
            <section key={tier.name}>
              <div className="mb-8 px-6 sm:px-4">
                <h2 className="text-3xl font-medium leading-6 text-gray-900">{tier.name}</h2>
                <p className="mt-4 text-base text-gray-500">{tier.description}</p>
              </div>

              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="border-t border-gray-200 bg-gray-50 py-3 px-6 text-left text-sm font-medium text-gray-900 sm:px-4">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => (
                      <tr key={feature.name} className="border-t border-gray-200">
                        <th className="py-5 px-6 text-left text-sm font-normal text-gray-500 sm:px-4" scope="row">
                          {feature.name}
                        </th>
                        <td className="py-5 pr-6 sm:pr-4">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-right text-sm text-gray-700">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckCircleIcon className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" />
                              ) : (
                                <MinusIcon className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{feature.tiers[tier.name] === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}


            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="h-px w-full table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th className="px-6 pb-4 text-left text-sm font-medium text-gray-900" scope="col">
                </th>
                {tiers.map((tier) => (
                  <th
                    key={tier.name}
                    className="w-1/4 px-6 pb-4 text-lg font-medium leading-6 text-gray-900 text-center"
                    scope="col"
                  >
                    {tier.name}
                    <span className="inline-block text-base text-gray-600 my-4">{tier.description}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 border-t border-gray-200">

              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-50 py-3 pl-6 text-left text-sm font-medium text-gray-900"
                      colSpan={4}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th className="py-5 px-6 text-left text-sm font-normal text-gray-500" scope="row">
                        {feature.name}
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.name} className="py-5 px-6 text-center">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-sm text-gray-700">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckCircleIcon className="h-5 w-5 text-green-500 m-auto" aria-hidden="true" />
                              ) : (
                                <MinusIcon className="h-5 w-5 text-gray-400 m-auto" aria-hidden="true" />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>

          </table>
        </div>
      </div>
    </div>
  )
}
