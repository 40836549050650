import Select from "../form/Select"
import ButtonGroup from "../form/ButtonGroup"
import FancySelect from "../form/FancySelect"

import { ArrowsPointingOutIcon, BanknotesIcon, AdjustmentsVerticalIcon, ListBulletIcon, MapPinIcon } from '@heroicons/react/20/solid'


const location_options = [
    {
      id: 1,
      name: 'Anywhere'
    },
    {
      id: 2,
      name: 'VII. district'
    },
    {
      id: 3,
      name: 'VI. district'
    },
]

const price_icon = <BanknotesIcon className="h-5 w-5" aria-hidden="true" />;
const price_options = [
    {
      id: 1,
      name: 'Any price'
    },
    {
      id: 2,
      name: '1.000-2.000',
    },
    {
      id: 3,
      name: '2.000-5.000',
    }
];

const size_icon = <ArrowsPointingOutIcon className="h-5 w-5" aria-hidden="true" />;
const size_options = [
    {
      id: 1,
      name: 'Any size'
    },
    {
      id: 2,
      name: '30-50',
    },
    {
      id: 3,
      name: '50-75',
    }
];

const more_filter_icon = <AdjustmentsVerticalIcon className="h-5 w-5" aria-hidden="true" />;
const more_filter_options = [
    {
      id: 1,
      name: 'More filter'
    },
    {
      id: 2,
      name: '?',
    },
    {
      id: 3,
      name: '?',
    }
];

const sort_by_buttons = [
    {
        name: 'Sort by date'
    },
    {
        name: 'Sort by price'
    }
];

const misc_buttons = [
    {
        name: <ListBulletIcon className="h-5 w-5" aria-hidden="true" />
    },
    {
        name: <MapPinIcon className="h-5 w-5" aria-hidden="true" />
    }
];

export default function Filter() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl pb-0 px-6 sm:pb-0 lg:px-8">

            <div className="h-10 grid gap-4 grid-cols-1 md:grid-cols-2 w-full mb-52 md:mb-10">
                <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                        <Select options={location_options} />
                        <FancySelect options={size_options} icon={size_icon} />
                        <FancySelect options={price_options} icon={price_icon} />
                    </div>
                </div>
                <div className="w-full md:flex md:justify-end">
                    <div className="w-full md:w-44">
                        <FancySelect options={more_filter_options} icon={more_filter_icon} />
                    </div>
                </div>
            </div>

            <div className="h-10 grid gap-4 grid-cols-1 md:grid-cols-2 w-full">
                <div className="w-full">
                    <div className="w-full text-center md:w-100 md:flex md:justify-start">
                        <ButtonGroup buttons={sort_by_buttons} />
                    </div>
                </div>
                <div className="w-full flex md:justify-end">
                    <div className="w-full text-center md:flex md:justify-end">
                        <ButtonGroup buttons={misc_buttons} />
                    </div>
                </div>
            </div>

        </div>
      </div>
    )
  }
