import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import LanguageSelector from '../language-selector/LanguageSelector';
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import A from '../link/A';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navigation() {
  const {t} = useTranslation(['navigation']);

  const location = useLocation();

  const solutions = [
    {
      name: t('property_management_label'),
      description: t('property_management_description'),
      href: 'property-management'
    },
    {
      name: t('leasing_label'),
      description: t('leasing_description'),
      href: 'leasing-services'
    },
    {
      name: t('renovation_label'),
      description: t('renovation_description'),
      href: 'renovation-services'
    },
    {
      name: t('sales_label'),
      description: t('sales_description'),
      href: 'property-sales'
    },
    {
      name: t('valutation_label'),
      description: t('valutation_description'),
      href: 'valuation-services'
    },
    {
      name: t('accounting_label'),
      description: t('accounting_description'),
      href: 'accounting-services'
    },
    {
      name: t('photography_label'),
      description: t('photography_description'),
      href: 'photography-services'
    }
  ];

  const main = [
    {
      name: t('rent_label'),
      href: 'rent'
    },
    {
      name: t('landlord_services_label'),
      href: '#',
      dropdown: solutions
    },
    {
      name: t('buy_label'),
      href: 'buy'
    },
    {
      name: t('about_us_label'),
      href: '#'
    },
    {
      name: t('contact_label'),
      href: 'contact'
    }
  ];


  return (
    <Popover className="relative bg-white border-b-2 border-gray-100 z-50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-6 xl:justify-start xl:space-x-10">

          <A href="" className="md:flex max-w-full flex-shrink-0">
            <img
              width="177"
              src="/jazz_immo_logo.svg"
              alt=""
            />
          </A>

          <div className="-my-2 -mr-2 xl:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>


          <Popover.Group as="nav" className="hidden space-x-10 xl:flex">
            {main.map((item, index) =>
              !item.dropdown  ? (
                <A href={item.href} key={index} className="hidden xl:flex text-base font-medium text-gray-500 hover:text-gray-900">
                  {item.name}
                </A>
              ) : (
                <Popover className="relative" key={item.name}>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        )}
                      >
                        <span>{ item.name }</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {item.dropdown.map((item, index) => (
                                <A
                                  key={index}
                                  href={item.href}
                                  className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                                >
                                  {/* <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600" aria-hidden="true" /> */}
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                  </div>
                                </A>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              )
            )}
          </Popover.Group>
          <div className="hidden items-center justify-end xl:flex xl:flex-1 xl:w-0">
            <LanguageSelector />
            <a
              href="https://app.jazzimmo.hu"
              className="ml-4 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-jazz-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
            >
              {t('log_in_label')}
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition xl:hidden">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-16 w-auto"
                    src="/jazz_immo_logo_small.png"
                    alt="Jazz Immo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item, index) => (
                    <A
                      key={index}
                      href={item.href}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      {/* <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600" aria-hidden="true" /> */}
                      <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                    </A>
                  ))}
                </nav>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">

              {main.map((item, index) => (
                !item.dropdown  ? (
                  <A href={item.href} key={index} className="text-base font-medium text-gray-900 hover:text-gray-700">{item.name}</A>
                ) : ("")
              ))}

              </div>
              <div>
                <a
                  href="https://app.jazzimmo.hu"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
