import { BoltIcon, EnvelopeIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline'

export default function HowToSwitch() {
  return (
    <div className="overflow-hidden">
      <div className="relative mx-auto max-w-7xl py-10 px-6 lg:px-8">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          <div className="lg:col-span-1">
            <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-3xl">How do I switch property management agency?</h2>
            <p className="mt-10 text-base text-gray-600">
            It couldn’t be easier to switch to Jazz Immo property management service, whether you have one property to manage or a whole portfolio. If you are currently with another agent, you should check your signed terms of business to make sure you are able to switch. Once you have the green light, we will send you across our terms of business to sign. If the property is owned by a company or a trust, other elements such as anti-money laundering (AML) would need to be met before instruction.
            </p>
            <p className="mt-10 text-base text-gray-600">
            Upon receiving the signed terms and conditions, we will look to begin marketing your property, ensuring that fresh photos are taken and advising on anything that needs to be done in advance of the property being let out. We will also require our own set of keys for viewings to commence.
            </p>
            <p className="mt-10 text-base text-gray-600">
            In case your property is already rented out we will request the lease agreement and contact the current tenant to invite them to use our Tenant App that will make rent collection and problem solving a lot smoother.Should you need to clean up utility meter registrations we will ask for authorizations and available documentation to make the name transfers of the meters. We will also look through the properties condominium accounts and set up a steady service charge payment flow, so you do not have to worry about your properties outstandings.
            </p>
          </div>
          <dl className="mt-10 lg:mt-20 sm:pt-10 grid grid-cols-1 gap-16 sm:grid-cols-1 sm:gap-x-12 lg:col-span-1">
                <img
                    className="" aria-hidden="true"
                    src="/images/how_to_switch.png"
                    alt=""
                />
          </dl>
        </div>
      </div>
    </div>
  )
}
