import { BoltIcon, EnvelopeIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';

export default function Services() {
  const {t} = useTranslation(['main']);


  const features = [
    {
      name: t('feature_1_name'),
      description: t('feature_1_description'),
      icon: "/images/moves_online.png",
    },
    {
      name: t('feature_2_name'),
      description: t('feature_2_description'),
      icon: "/images/moves_online.png",
    },
    {
      name: t('feature_3_name'),
      description: t('feature_3_description'),
      icon: "/images/moves_online.png",
    }
  ];

  return (
    <div className="overflow-hidden bg-jazz-grey">
      <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8 lg:py-40">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          <div className="lg:col-span-1">
            <h2 className="text-lg font-semibold text-indigo-600 mb-4">{ t('we_offer') }</h2>
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">{ t('landlord_services') }</h2>
            <p className="mt-2 text-base text-gray-600">{ t('landlord_services_description') }</p>
          </div>
          <dl className="mt-20 grid grid-cols-1 gap-16 sm:grid-cols-1 sm:gap-x-12 lg:col-span-1 lg:mt-0">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                    <img
                        className="absolute mt-0 text-indigo-600" aria-hidden="true"
                        src={feature.icon}
                        alt=""
                    />
                  <p className="mt-6 pt-5 ml-20 text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-20 text-base text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
