import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    CogIcon,
    LockClosedIcon,
    ServerIcon,
    ShieldCheckIcon,
  } from '@heroicons/react/24/outline'

  const features_first_row = [
    {
      name: 'Leasing Services',
      description: 'We have a team of leasing agents with over 20 years experience in the Budapest letting market. Our Lease Agreements protect your interest and trigger actions through the JAZZ IMMO APP for deposit and rental charges.',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Marketing',
      description: 'We have a coverage across a vast network of listing sites, use off-line tools, we cooperate with educational institutions and expat relocation agencies in order to minimize void periods and start to generate cash-flow.',
      icon: LockClosedIcon,
    },
    {
      name: 'Money collection',
      description: 'Our property management system ensures the our ability of timely rent collection. Your rental income is deposited on your account on a monthly basis. We also collect utility and common cost payments and make sure all service providers bills are paid on time.',
      icon: ArrowPathIcon,
    }
  ]

  export default function Yields() {
    return (
      <div className="relative bg-white py-10 sm:py-32 lg:py-0">
        <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
          <h2 className="text-lg font-semibold text-indigo-600">We will look after your cash-flow!</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Yields
          </p>
          <div className="mt-20">
            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
              {features_first_row.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-xl bg-indigo-500 p-3 shadow-lg">
                          <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base leading-7 text-gray-600">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
