export default function Quote(props) {
    let {title, subtitle, cta} = props;

    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-16 px-6 sm:pb-10 pt-8 lg:px-8">
          <div className="text-center">
            <p className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 sm:text-3xl lg:text-3xl">
              {title}
            </p>
            <p className="mx-auto mt-8 max-w-4xl text-xl text-gray-500 mb-10 leading-relaxed">
              {subtitle}
            </p>

            {cta}
          </div>
        </div>
      </div>
    )
  }
