import { useState } from 'react'
import Select from '../form/Select';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const interested_in_options = [
    {
        id: 0,
        name: 'Select purpose'
    },
    {
        id: 1,
        name: 'Propery management',
    },
    {
        id: 2,
        name: 'Leasing',
    },
    {
        id: 3,
        name: 'Renovation',
    },
    {
        id: 4,
        name: 'Sales',
    },
    {
        id: 5,
        name: 'Valuation',
    },
    {
        id: 6,
        name: 'Accounting',
    },
    {
        id: 7,
        name: 'Photography',
    }
];

const number_of_properties_options = [
    {
        id: 0,
        name: 'Select number'
    },
    {
        id: 1,
        name: '1'
    },
    {
        id: 2,
        name: '2-5'
    },
    {
        id: 3,
        name: '5-10'
    },
    {
        id: 4,
        name: '10+'
    },
]


export default function Form() {
  const [agreed, setAgreed] = useState(false)

  return (
    <div className="overflow-hidden bg-white py-16 px-6 lg:px-8 lg:py-24">
      <div className="relative mx-auto max-w-xl">

        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Get in touch with us</h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
          Let us support you with your Budapest real-estate!
          </p>
        </div>
        <div className="mt-12">
          <form action="#" method="POST" className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                First name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                Last name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">

                <input
                  type="text"
                  name="phone-number"
                  id="phone-number"
                  autoComplete="tel"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="+1 (555) 987-6543"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                What are you interested in?
              </label>
              <div className="mt-1">
                <Select options={interested_in_options} />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Number of properties
              </label>
              <div className="mt-1">
                <Select options={number_of_properties_options} />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  defaultValue={''}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-start">
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                    <input
                        id="comments"
                        aria-describedby="comments-description"
                        name="comments"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    </div>
                    <div className="ml-3 text-sm">
                    <label htmlFor="comments" className="font-medium text-gray-700">
                        You agree to our friendly <a href="#" className="font-medium text-gray-700 underline">Privacy policy</a>.
                    </label>
                    </div>
                </div>

              </div>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-jazz-blue px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Send message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
