export default function Select(props) {
    let {options} = props;

    return (
      <div>
        <select
          id="location"
          name="location"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue="1"
        >
          {options.map((item, index) => (
            <option key={index}>{item.name}</option>
          ))}
        </select>
      </div>
    )
  }
