import A from '../link/A';

export default function CtaBottom(props) {
    let{title, subtitle, button_text, href} = props
    return (
      <div className="bg-[#F9FAFB] max-w-7xl mt-10 mb-20 lg:my-20 rounded-3xl mx-6 xl:mx-auto">
        <div className="mx-auto max-w-7xl py-10 px-6 lg:flex lg:items-center lg:justify-between lg:px-8">
          <h2 className="md:text-4xl">
            <span className="block text-lg font-semibold">{title}</span>
            <span className="block text-base font-base">{subtitle}</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <A
                href={href}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-jazz-blue px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
              >
                {button_text}
              </A>
            </div>
          </div>
        </div>
      </div>
    )
  }
