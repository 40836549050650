import A from '../link/A';

export default function FindATenant() {
  return (
    <div className="overflow-hidden">
      <div className="relative mx-auto max-w-7xl py-10 px-6 sm:py-10 lg:px-8 lg:py-20">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
          <div className="lg:col-span-1">
            <h2 className="text-lg font-semibold text-indigo-600 mb-4">We help with</h2>
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">Find a tenant</h2>
            <p className="mt-2 text-base text-gray-600">Let your property work for you and discover how simple it can be to locate a tenant with a trusted partner by your side. Our specialist property letting services means everything is taken care of, from promoting your property, drafting rental agreements to matching it with the ideal tenants. We will take professional photos of the property, organize viewings and select the suitable tenant. We’ll manage the process thoroughly from start to finish.</p>

            <A
              href="contact"
              className="bg-jazz-blue text-white hover:bg-indigo-600 mt-8 block w-100 sm:w-1/2 md:w-1/2 xl:w-1/2 py-3 px-6 border border-transparent rounded-md text-center font-medium"
            >
              Start to find a tenant now
            </A>
          </div>
          <dl className="mt-20 grid grid-cols-1 gap-16 sm:grid-cols-1 sm:gap-x-12 lg:col-span-1 lg:mt-0">
                <img
                    className="" aria-hidden="true"
                    src="/images/property_sales.jpg"
                    alt=""
                />
          </dl>
        </div>
      </div>
    </div>
  )
}
